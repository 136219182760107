/*=========================================================================================
  File Name: moduleState.js
  Description: AllAllCompanyParty Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleDesignationState.js'
import mutations from './moduleDesignationMutations.js'
import actions from './moduleDesignationActions.js'
import getters from './moduleDesignationGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}