<template>
  <div>
    <v-img alt="Logo" src="/images/no_data.png" contain height="500" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>