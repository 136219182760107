<!-- =========================================================================================
  File Name: ProductList.vue
  Description: Product List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in/
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <v-card :color="themeConfig.cardBg1">
      <v-card-title>
        <h4 class="my-page-tilte">{{ PageTitle }}</h4>
        <v-spacer />
        <v-text-field
          v-if="rows.length > 0"
          v-model="search"
          flat
          solo
          prepend-inner-icon="mdi-magnify"
          class="my-search-box shrink"
          density="compact"
          label="Search..."
          variant="outlined"
          placeholder="Enter text to search"
        ></v-text-field>
        <router-link to="/purchase-order/create" v-if="CreateButtonFlag">
          <v-btn medium elevation="30" rounded class="ml-3 my-primary-button">
            <v-icon left> mdi-plus-box </v-icon> Create
          </v-btn>
        </router-link>
        <v-btn
          @click.prevent="refreshPageData"
          medium
          elevation="30"
          rounded
          class="ml-3 my-info-button"
        >
          <v-icon left> mdi-sync </v-icon> Refresh
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row wrap v-if="StartupLoadingFlag">
          <v-col cols="12" md="4" v-for="n in 6" :key="n">
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-card
          elevation="6"
          outlined
          id="my-data-table"
          v-if="!StartupLoadingFlag"
          :color="themeConfig.cardBg2"
        >
          <v-card-text>
            <no-data-found v-if="rows.length == 0" />
            <v-form
              ref="form1"
              v-model="valid1"
              lazy-validation
              v-on:submit.prevent="submitForm"
            >
              <v-row wrap v-if="BranchFlag">
                <v-col cols="12" sm="6" md="3" lg="3" align="left">
                  <label class="my-label">
                    <span class="text-danger">*</span> Branch
                  </label>
                  <v-autocomplete
                    persistent-hint
                    hint="Please select the branch"
                    prepend-inner-icon="fa fa-location-dot"
                    :reduce="(option) => option.value"
                    :loading="BranchOptionsLoading"
                    :items="BranchOptions"
                    :rules="BranchRules"
                    v-model="Branch"
                    required
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <p></p>
                  <v-btn
                    color="primary"
                    class="me-3 mt-3"
                    type="submit"
                    @click.prevent="searchForm"
                    :loading="SubmitFlag"
                  >
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <br />
            <v-data-table
              v-if="rows.length > 0"
              class="elevation-1"
              v-model="selected"
              :search="search"
              :items="rows"
              :headers="TableColumns1"
              :item-key="TableOptions1.ItemKey"
              :items-per-page="TableOptions1.ItemsPerPage"
              :footer-props="TableOptions1.FooterProps"
              :single-select="TableOptions1.SingleSelectFlag"
              :show-select="TableOptions1.ShowSelectFlag"
            >
              <template v-slot:[`item.StatusTxt`]="{ item }">
                <v-chip
                  draggable
                  dark
                  :color="item.StatusBgColor"
                  :text-color="item.StatusTextColor"
                  >{{ item.StatusTxt }}</v-chip
                >
              </template>
              <template v-slot:[`item.ProductImage1`]="{ item }">
                <img width="100" height="100" :src="item.ProductImage1" />
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template
                    v-slot:activator="{ on, attrs }"
                    v-if="item.PreviewFlag"
                  >
                    <v-icon
                      icon
                      size="25px"
                      color="primary"
                      @click="previewAlert(item, e)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-magnify
                    </v-icon>
                  </template>
                  <span> Preview </span> </v-tooltip
                >&nbsp;&nbsp;&nbsp;
                <v-tooltip bottom>
                  <template
                    v-slot:activator="{ on, attrs }"
                    v-if="item.VerifyFlag"
                  >
                    <v-icon
                      icon
                      size="25px"
                      color="blue"
                      @click="verifyAlert(item, e)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-lock-check
                    </v-icon>
                  </template>
                  <span> Verify </span> </v-tooltip
                >&nbsp;&nbsp;&nbsp;
                <v-tooltip bottom>
                  <template
                    v-slot:activator="{ on, attrs }"
                    v-if="item.ApprovalFlag"
                  >
                    <v-icon
                      icon
                      size="25px"
                      color="success"
                      @click="approvalAlert(item, e)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-shield-check
                    </v-icon>
                  </template>
                  <span> Approve </span> </v-tooltip
                >&nbsp;&nbsp;&nbsp;
                <v-tooltip bottom>
                  <template
                    v-slot:activator="{ on, attrs }"
                    v-if="item.EditFlag"
                  >
                    <v-icon
                      icon
                      size="25px"
                      color="warning"
                      @click="editAlert(item, e)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-file-document-edit
                    </v-icon>
                  </template>
                  <span> Edit </span> </v-tooltip
                >&nbsp;&nbsp;&nbsp;
                <v-tooltip bottom>
                  <template
                    v-slot:activator="{ on, attrs }"
                    v-if="item.DeleteFlag"
                  >
                    <v-icon
                      icon
                      size="25px"
                      color="red"
                      v-if="CancelFlag"
                      @click="deleteAlert(item, e)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-cancel
                    </v-icon>
                  </template>
                  <span> Cancel </span>
                </v-tooltip>
                   <template>
                  <v-tooltip bottom v-if="item.FilePathFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        :href="item.FilePathUrl"
                        target="_blank"
                        download
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="red">mdi-file-pdf-box</v-icon>
                      </a>
                    </template>
                    <span>Download pdf</span>
                  </v-tooltip>
                </template>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog v-model="previewRecordPrompt" persistent max-width="75%">
      <purchase-order-preview
        :pageDescription="$t('Purchase')"
        :rowData="selected"
        :showDialog="previewRecordPrompt"
        @hideDialog="hidePreviewRecordPrompt($event)"
        v-if="previewRecordPrompt"
      ></purchase-order-preview>
    </v-dialog>
    <v-dialog v-model="verifyRecordPrompt" persistent max-width="75%">
      <purchase-order-verify
        pageDescription="Purchase Order Verify"
        :rowData="selected"
        :showDialog="verifyRecordPrompt"
        @hideDialog="hideVerifyAlert($event)"
        v-if="verifyRecordPrompt"
      ></purchase-order-verify>
    </v-dialog>
    <v-dialog v-model="approveRecordPrompt" persistent max-width="75%">
      <purchase-order-approval
        pageDescription="Purchase Order Approval"
        :rowData="selected"
        :showDialog="approveRecordPrompt"
        @hideDialog="hideApprovalAlert($event)"
        v-if="approveRecordPrompt"
      ></purchase-order-approval>
    </v-dialog>
  </div>
</template>

  <script>
import companyConfig from "@/company_config.json";
import common from "@/Common.vue";
import themeConfig from "@/themeConfig.js";
import PurchaseOrderPreview from "@/views/purchase_order/PurchaseOrderPreview.vue";
import PurchaseOrderVerify from "@/views/purchase_order/PurchaseOrderVerify.vue";
import PurchaseOrderApproval from "@/views/purchase_order/PurchaseOrderApproval.vue";

export default {
  mixins: [common],
  components: {
    PurchaseOrderPreview,
    PurchaseOrderVerify,
    PurchaseOrderApproval,
  },
  data() {
    return {
      selected: [],
      itemsPerPage: 10,
      isMounted: false,

      valid1: false,
      CancelFlag: false,
      StartupLoadingFlag: false,
      SubmitFlag: false,
      themeConfig: themeConfig,

      search: "",
      rows: [],
      TableColumns1: [],
      TableOptions1: {
        CreateButtonFlag: false,
      },

      message: "",

      verifyRecordPrompt: false,
      approveRecordPrompt: false,

      addRecordPrompt: false,
      editRecordPrompt: false,
      addVariantPrompt: false,
      previewRecordPrompt: false,
      recordToEdit: "",
      getAllRecords: {},
      record: {},

      BranchRules: [(v) => !!v || "Branch is required"],
      // Branch: "",
      BranchOptions: [],
      BranchOptionsLoading: false,
      BranchFlag: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CreateButtonFlag: false,
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    TableContent() {
      return this.$store.getters["purchaseOrderStore/dataList"];
    },
    Branch() {
      console.log("PrimaryBranch computed called");
      var tr = this.getBranchData();
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      var id = n1 > 0 ? tr.BranchId : null;
      return id;
    },
    StatusFlag() {
      console.log("StatusFlag computed called");
      var StatusFlag = this.$store.getters["purchaseOrderStore/StatusFlag"];
      console.log({ StatusFlag });
      return StatusFlag;
    },
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.StartupLoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        var PagePermissionFlag = tr.PagePermissionFlag;
        console.log({ PagePermissionFlag });

        if (!PagePermissionFlag) {
          this.$router.push("/access-denied").catch(() => true);
        } else {
          this.pageData();
        }
      }
    },
    StatusFlag: function () {
      console.log("watch StatusFlag called");

      var StatusFlag = this.StatusFlag;
      console.log({ StatusFlag });

      if (StatusFlag) {
        this.searchForm();
      }
    },
    Branch: function () {
      this.CreateButtonFlag = false;
      var Branch = this.Branch;
      console.log({ Branch });
      if (Branch > 0) {
        this.pageData();
      }
    },
    TableContent: function () {
      console.log("watch TableContent called");

      var records = this.TableContent;
      console.log({ records });

      this.TableOptions1 = records.TableOptions;
      this.TableColumns1 = records.TableHeader;
      this.rows = records.TableData;
    },
    BranchOptions: function () {
      console.log("watch BranchOptions");
      this.BranchOptionsLoading = false;
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");

      this.StartupLoadingFlag = true;
      this.CreateButtonFlag = false;
      this.rows = [];

      // var CompanyId = this.$session.get('CompanyId')
      var BranchCode = this.BranchCode;
      console.log("BranchCode=" + BranchCode);

      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "purchase_order",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      // loader.hide();
    },
    backPage() {
      this.$router.go(-1);
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
    },
    hidePreviewRecordPrompt(flag) {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
      console.log({ flag });
      this.selected = {};
    },
    hideVerifyAlert(flag) {
      console.log("hideVerifyAlert called");
      console.log({ flag });
      this.verifyRecordPrompt = false;
      this.selected = {};
      if (flag) {
        this.TableOptions1 = {};
        this.TableColumns1 = [];
        this.rows = [];
        this.searchForm();
      }
    },
    verifyAlert(tr) {
      console.log("verifyAlert called");
      console.log({ tr });
      var n = Object.keys(tr).length;
      console.log("n=" + n);
      if (n > 0) {
        this.selected = tr;
        this.verifyRecordPrompt = true;
      } else {
        var output = "Kindly select record to verify";
        this.errorMessage(output);
      }
    },
    hideApprovalAlert(flag) {
      console.log("hideApprovalAlert called");
      console.log({ flag });
      this.approveRecordPrompt = false;
      this.selected = {};
      if (flag) {
        this.TableOptions1 = {};
        this.TableColumns1 = [];
        this.rows = [];
        this.searchForm();
      }
    },
    approvalAlert(tr) {
      console.log("approvalAlert called");
      console.log({ tr });
      var n = Object.keys(tr).length;
      console.log("n=" + n);
      if (n > 0) {
        this.selected = tr;
        this.approveRecordPrompt = true;
      } else {
        var output = "Kindly select record to approve";
        this.errorMessage(output);
      }
    },
    pageData() {
      console.log("pageData called");
      var condition1 = {
        UserInterface: 1,
        Branch: this.Branch,
        Module: "purchase_order",
        Action: "create",
      };
      this.getActionPermission("CreateButtonFlag", false, condition1);
      this.searchForm();
    },
    getBranchOptions(n) {
      console.log("getBranchOptions called");
      this.LoadingFlag = true;
      var selectbox1_source = "Branch";
      var selectbox1_destination = "BranchOptions";
      var selectbox1_url = "api/branch/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm called");

      // Fetch records and store

      const loader = this.$loading.show({});

      var server_url = companyConfig.apiURL;
      var getAllRecords = {};
      getAllRecords.alert_flag = false;
      getAllRecords.token = this.$session.get("token");
      getAllRecords.headers = this.getApiHeaders();
      getAllRecords.list_url = server_url + "api/purchase-order/lists";
      getAllRecords.list_where = {
        UserInterface: 1,
        Branch: this.Branch,
      };
      console.log("beforeMount getAllRecords=" + JSON.stringify(getAllRecords));
      getAllRecords.router = this.$router;
      getAllRecords.loading = loader;
      getAllRecords.notify = this.sweetAlert;
      getAllRecords.http = this.$http;
      this.$store.dispatch("purchaseOrderStore/loadAllRecords", getAllRecords);
    },
    addData() {
      var params = {};
      var url = "/ledgers/group/create";
      this.pageRedirect(params, url);
    },
    editAlert(tr) {
      console.log("editAlert called");
      console.log({ tr });
      var n = Object.keys(tr).length;
      console.log("n=" + n);
      if (n > 0) {
        var alertTitle = "Do you want to edit details?";

        var htmlTxt = "";
        htmlTxt += "<br/> Staff Name <b> " + tr.StaffNameTxt + "</b>";

        Swal.fire({
          title: alertTitle,
          // icon: "info",
          iconHtml:
            '<img class="my-edit-alert" src="' +
            this.themeConfig.infoImg +
            '">',
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#fff",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.editData(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var output = "Kindly select record to edit";
        this.errorMessage(output);
      }
    },
    editData(tr) {
      console.log("editData called");
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1);

      if (n1 > 0) {
        var url = "/staff/edit";
        var id1 = tr.BranchId;
        console.log({ id1 });
        var id2 = tr.StaffCode;
        console.log({ id2 });
        var params = {
          id1: id1,
          id2: id2,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one event to edit";
        this.toast("error", message);
      }
    },

    previewAlert(tr) {
      console.log("previewAlert called");
      console.log({ tr });
      var n = Object.keys(tr).length;
      console.log("n=" + n);
      if (n > 0) {
        this.selected = tr;
        this.previewRecordPrompt = true;
      } else {
        var output = "Kindly select record to preview";
        this.errorMessage(output);
      }
    },

    deleteAlert(tr) {
      console.log("deleteAlert called");
      console.log({ tr });
      var n = Object.keys(tr).length;
      console.log("n=" + n);
      if (n > 0) {
        var alertTitle = "Do you want to delete details?";

        var htmlTxt = "";
        htmlTxt += "<br/> Staff Name <b> " + tr.StaffNameTxt + "</b>";

        Swal.fire({
          title: alertTitle,
          // icon: "info",
          iconHtml:
            '<img class="my-edit-alert" src="' +
            this.themeConfig.deleteImg +
            '">',
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#fff",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var output = "Kindly select record to delete";
        this.errorMessage(output);
      }
    },
    deleteData(tr) {
      console.log("deleteData called");
      console.log({ tr });
      var n = Object.keys(tr).length;
      console.log({ n });

      if (n > 0) {
        let upload = {
          UserInterface: 1,
          BranchStaff: tr.BranchStaffId,
          Branch: tr.BranchId,
          Staff: tr.StaffId,
        };
        console.log({ upload });

        const loader = this.$loading.show({});

        var server_url = companyConfig.apiURL;
        var getAllRecords = {};
        getAllRecords.redirect_flag = false;
        getAllRecords.alert_flag = true;
        getAllRecords.token = this.$session.get("token");
        getAllRecords.headers = this.getApiHeaders();
        getAllRecords.add_url = server_url + "api/branch-asset/destroy";
        getAllRecords.upload = upload;
        getAllRecords.loading = loader;
        getAllRecords.notify = this.sweetAlert;
        getAllRecords.http = this.$http;
        this.$store.dispatch(
          "purchaseOrderStore/addRecordDetails",
          getAllRecords
        );
      }
    },
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      console.log("your session is expired");
      this.$router.push("/");
    }
  },
  beforeMount() {
    if (!this.$session.exists()) {
      console.log("your session is expired");
      this.$router.push("/");
    } else {
      this.refreshPageData();
      this.checkPrimaryBranch();
    }
  },
  mounted() {
    this.pageLoadingOff();
    this.isMounted = true;
  },
};
</script>

  <style lang="scss">
#data-list-list-view {
}
</style>
