<template>
  <div id="side-bar">
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3">
        <!--begin::Avatar-->
        <div class="symbol symbol-50px me-5">
          <img alt="Logo" src="../assets/media/avatars/300-1.jpg" />
        </div>
        <!--end::Avatar-->
        <!--begin::Username-->
        <div class="d-flex flex-column">
          <div class="fw-bolder d-flex align-items-center fs-5">
            {{ User.Username }}
            <span
              class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2"
            ></span>
          </div>
        </div>
        <!--end::Username-->
      </div>
    </div>
    <v-divider />
    <div class="px-5 mb-4">
      <router-link to="/change-password">
        <v-icon size="20">mdi-account-key</v-icon>
        <span class="text-h6 ml-2">Change Password</span>
      </router-link>
    </div>
    <!-- <div class="px-5 mb-4">
      <router-link to="/hover-menu2">
        <v-icon size="20">mdi-view-dashboard</v-icon>
        <span class="text-h6 ml-2">Dashbaord</span>
      </router-link>
    </div> -->
    <v-divider />
    <div class="px-5">
      <router-link to="/login">
        <v-icon class="text-danger" size="20">mdi-logout-variant</v-icon>
        <span class="text-h6 text-danger ml-2">Log Out</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  mixins: [],
  data() {
    return {
      User: {},
    };
  },
  beforeMount() {
    console.log("User SideBar beforeMount");
    var user = this.$session.get("user");
    console.log({ user });
    this.User = user;
  },
};
</script>